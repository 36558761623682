// User Menu
.navbar-nav > .user-menu {
  @include border-bottom-radius(4px);
  > .nav-link:after {
    content:none;
  }

  > .dropdown-menu {
    @include border-top-radius(0);
    padding: 0;
    width: 280px;
    right:0;
    left: auto;

    // Header menu
    > .user-header {
      height: 175px;
      padding: 10px;
      text-align: center;

      // User image
      > img {
        z-index: 5;
        height: 90px;
        width: 90px;
        border: 3px solid;
        border-color: transparent;
        border-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
      }

      > p {
        z-index: 5;
        font-size: 17px;
        margin-top: 10px;

        > small {
          display: block;
          font-size: 12px;
        }
      }
    }

    > .user-body {
      @include clearfix;
      border-bottom: 1px solid $gray-300;
      border-top: 1px solid $gray-300;
      padding: 10px;
    }

    > .user-footer {
      @include clearfix;
      background-color: $gray-100;
      padding: 10px;
    }
  }

  .user-image {
    @include media-breakpoint-up(sm) {
      float: none;
      line-height: 10px;
      margin-right: .4rem;
      margin-top: -8px;
    }

    border-radius: 50%;
    float: left;
    height: 25px;
    margin-right: 10px;
    width: 25px;
  }
}
