
.auth-sidebar {
  position:relative;

  header {
    margin: 0 auto;
    max-width: 416px;
    padding: 48px 20px 0;
    text-align: left;

    .logo {
      display:block;
      margin-bottom:30px;
    }

    h1 {
      display:none;
      font-family: FontPft, Helvetica, Arial, sans-serif;
      color: #fff;
    }
  }

  .artwork {
    display:none;

   .artwork-image {
      -ms-flex-positive: 1;
      flex-grow: 1;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
    }
    .artwork-attribution {
      font-size:14px;
      line-height:14px;
      padding:25px 64px 35px;
      margin:0;
      color: #fff;
    }
  }
}

.auth-content-section {
  main {
    margin:0 auto;
    padding:0 20px
  }
}

.auth-content {
  width:100%;
  max-width: 416px;
}

.sign-in .artwork-image,
.forgot-password .artwork-image,
.reset-password .artwork-image {
  background-image: url("https://dummyimage.com/1128x1128/489cd8/fff.png")
}
.sign-up .artwork-image {
  background-image: url("https://dummyimage.com/1128x1128/489cd8/fff.png")
}

.auth-nav {
  display:none;
  position:relative
}

.auth-link-mobile {
 text-align:center
}

.auth-nav .back-link i {
  font-size: 12px
}

@media (min-width: 960px) {
  #main-container {
    height:100%;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-direction:row;
    flex-direction:row;
    overflow:hidden
  }

  .auth-sidebar {
    width:450px;
    -ms-flex-positive:0;
    flex-grow:0;

   .auth-sidebar-content {
      display:-ms-flexbox;
      display:flex;
      -ms-flex-direction:column;
      flex-direction:column;
      -ms-flex-pack:justify;
      justify-content:space-between;
      height:100%
    }

    header {
      max-width:100%;
      margin:0;
      padding:40px 40px 30px 40px;
      .logo {
       opacity:0.7;
       &:hover {
         opacity:0.9;
       }
      }
      h1 {
       display:block;
       padding-right:30px;
      }
    }

    .artwork {
      display:-ms-flexbox;
      display:flex;
      -ms-flex-direction:column;
      flex-direction:column;
      -ms-flex-pack:end;
      justify-content:flex-end;
      -ms-flex-positive:1;
      flex-grow:1;
    }
  }

  /*
  .sign-in .auth-sidebar,
  .forgot-password .auth-sidebar,
  .reset-password .auth-sidebar {
    background:#f1cdd7;
    color:#865c6c;
  }

  .sign-up .auth-sidebar {
    background:#f2d184;
    color:#866118;
  }
  */

  .auth-nav {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:end;
    justify-content:flex-end;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-positive:0;
    flex-grow:0;
    padding:30px 30px 0 30px;
    p {
     margin:0;
     -ms-flex-positive:1;
     flex-grow:1;
     text-align:right
    }

    .back-link {
      display:-ms-flexbox;
      display:flex;
      -ms-flex-align:center;
      align-items:center;
      -ms-flex-pack:center;
      justify-content:center;
      -ms-flex-item-align:start;
      align-self:flex-start;
      width:40px;
      height:40px;
      border-radius:50%;
      border:1px solid #e0e0e0;
      color:#757575;
      &:hover {
       border-color:#ddd;
       color:#444
      }
    }
  }

  .auth-content-section {
    margin: 0;
    padding: 0;

    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1;
    flex: 1;
    overflow: auto;

    main {
      display:-ms-flexbox;
      display:flex;
      -ms-flex-align:center;
      align-items:center;
      -ms-flex-pack:center;
      justify-content:center;
      -ms-flex-positive:1;
      flex-grow:1;
      margin:0;
      padding:0
    }

    .auth-content {
      padding: 30px 60px 0;
      margin: 0;
    }
  }

  .sign-up .auth-content {
    max-width: 630px;
  }

 .auth-link-mobile {
    display:none;
  }
}

@media (min-width: 1100px) {
 .auth-sidebar {
  width:514px
 }
 .auth-sidebar header {
  padding:64px 64px 30px 64px
 }
}

@media (max-height: 850px) {
 .auth-sidebar header h1 {
  font-size:25px;
  line-height:30px
 }
}

@media (max-width: 961px), (max-height: 730px) {
 .auth-sidebar .artwork .artwork-image {
  display:none
 }
 .auth-sidebar .artwork .artwork-attribution {
  display:none
 }
}

@media (min-height: 960px) {
 .auth-sidebar .artwork .artwork-image {
  max-height:70%
 }
}
