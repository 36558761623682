
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// See https://drafts.csswg.org/mediaqueries-5/#prefers-reduced-motion
$enable-prefers-reduced-motion-media-query: false;
$enable-responsive-font-sizes: true;
$enable-rounded: true;
$enable-gradients: true;

$yellow: #f4d111;

$primary: #b1317f;
$light: #e9ecef;
$dark: #5c5959;

$navbar-dark-brand-color: rgba(255,255,255,0.85);
$navbar-dark-brand-hover-color: #fff;
$navbar-dark-color: rgba(255,255,255,0.85);
$navbar-dark-active-color: #fff;

$card-spacer-y: .5rem;
$card-spacer-x: 0.75rem !default;
$card-cap-bg: transparent;
$card-border-color: rgba(#000000, .09);

$carousel-caption-color: #333;
$input-placeholder-color: #adb5bd;

$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;

@import "~bootstrap/scss/variables";

// Bootstrap optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

@import 'fine-uploader';

@import "auth";

@import "user-menu";

html, body {
  height: 100%;
}

.no-border {
  border: none !important;
}

.navbar a, .nav a {
  outline: none;
}

.btn-custom {
  @include button-variant($secondary, $secondary);
}

.card-footer {
  border-top: none;
  font-style: italic;
}

.checkbox input {
  margin-right: 0.5rem;
}

.article-body img {
  max-width: 100%;
}

// Zend Framework 1 generate form errors
ul.errors {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    margin: 0.25rem 0;
    padding: 0;
    color: $red;
  }
}

#home-carousel {
  .bg-image {
    margin: 0 auto;
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    .carousel-caption {
        text-align: left;
        font-weight: bold;
        h2 {
            font-size: 2rem;
        }
    }
  }
}


@include media-breakpoint-up(xl) {
  #home-carousel {
    .bg-image {
      max-width: 1342px;
      position: relative;

      .carousel-caption {
          right: auto;
          max-width: 800px;
          left: 2%;
          h2 {
              font-size: 3rem;
          }

      }
    }
    .carousel-indicators {
      right: 0;
      top: 20px;
      bottom: auto;
      left: auto;
      justify-content: right;
      margin-right: 2%;
      margin-left: auto;
    }
  }
}

/*
#content {
  font-family: FontPft, "Helvetica Neue", Arial, sans-serif;
}
*/

#footer {
  min-height: 60px;
  background-color: $gray-700;
  border-top: 1px solid $gray-200;
  padding-top: 1rem;
  font-size: 0.9rem;
}

#mainmenu .megamenu .dropdown-menu {
  border-top: none;
  &> ul li {
      list-style-type: none;
      position: relative;
  }
  &.bg-dark {
    a {
      color: $white;
      font-weight: bold;
      text-decoration: none;
      font-size: 1.1rem;
      &:hover {
        color: $gray-200;
      }
    }
    ul.nav {
      background-color: $gray-900;
    }
  }
  p {
    font-size: 0.85rem;
  }
}

@include media-breakpoint-up(lg) {
  #mainmenu .megamenu .dropdown-menu {
    min-width: 700px;

    &> ul.list-group {
        margin: 0 -1rem;
    }

    &> ul.list-group li {
        margin: 0 1rem;
        width:150px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

#main-search-input {
  border-color: $gray-600;
  border-right: 1px
}

.card-4-columns {
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

// Footer

#subscribeForm {
  .alert {
    display: none
  }
  &.error {
    .alert-danger {
      display: block;
    }
  }
  &.success {
    .alert-success {
      display: block;
    }
  }
}

.footer-nav {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}

// Back to top button
.back-to-top {
  position: fixed;
  display: none;
  background: $primary;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

// Gallery
.gallery-image {
  .card-image {
    position: relative;
  }
  .share-bar {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    .navbar-nav li {
      a {
          font-size: 20px;
        &:hover {
            color: #fff;
        }
        &.likes {
            color: darkred;
        }
      }

      &.divider-vertical {
          height: 38px;
          border-left: 1px solid #c5c5c5;
          border-right: 1px solid #efefef;
      }

      .st_facebook_large, .st_twitter_large {
        display: block;
        box-sizing: content-box;
        height: 32px;
        width: 32px;
        padding: 0.5rem;
      }
    }
  }
  &:hover {
    .share-bar {
      display: block;
    }
  }
}

#filter-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    i.filter-remove {
      font-size: 22px;
      border: 1px solid;
      padding: 5px;
      color: #c5c5c5;
      cursor: pointer;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }
}

.Badge {
  width: 60px;
  height: 60px;
  background: url(../img/badges.png) no-repeat;
  position: absolute;
  right: -15px;
  top: 5px;

  &.Badge-0 {
    display: none;
  }

  &.Badge-1 {
    background-position: 0 -80px;
  }

  &.Badge-2 {
    background-position: 0 -160px;
  }

  &.Badge-3 {
    background-position: 0 -240px;
  }

  &.Badge-Live {
    background-position: 0 0;
  }

  &.Badge-Takepart {
    background: url(../img/Badge-Takepart.png) no-repeat;
    right: 5px;
    top: 5px;
  }
}

// Projects

img.live-project {
  position: absolute;
  right: -2px;
  top: -2px;
}

// Hide Sharethis gdpr iframe
#st_gdpr_iframe {
  border: 0;
}
